import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const self = this
    const tm = Number(self.data.get("timeout"))
    self.timeout = setTimeout(() => self.close(), tm)
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  handleClick(evt) {
    evt.preventDefault()
    this.close()
  }

  close() {
    this.element.remove();
  }
}
