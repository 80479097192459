import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "submitBtn" ]

  connect() {}

  handleSubmit(evt) {
    this.submitBtnTarget.disabled = true
    const fieldsWithError = this.inputTargets.filter(el => !this.getInput(el).validateAndShow())

    if (fieldsWithError.length > 0) {
      evt.preventDefault()
      this.submitBtnTarget.disabled = false
      this.getInput(fieldsWithError[0]).focus()
    }
  }

  getInput(el) {
    return this.application.getControllerForElementAndIdentifier(el, "input")
  }
}
