import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "sheet", "bg", "content", "itemText" ]

  connect() {
  }

  toggle() {
    const width = document.documentElement.clientWidth
    if (width >= 1280) { // LG
      this.sheetTarget.classList.toggle("lg:w-18")
      this.contentTargets.forEach(item => item.classList.toggle("lg:pl-64"))
      this.itemTextTargets.forEach(item => item.classList.toggle("lg:hidden"))
    } else {
      this.bgTarget.classList.toggle("hidden")
      this.sheetTarget.classList.toggle("-ml-64")
    }
  }
}
