import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "themeIcon" ]

  connect() {}

  toggleTheme() {
    const currentTheme = this.data.get("theme")
    const newTheme = currentTheme === "light" ? "dark" : "light"

    this.themeIconTarget.textContent = `brightness_${newTheme === "dark" ? '7' : '4'}`
    this.data.set("theme", newTheme)
    document.querySelectorAll(`[class*='${currentTheme}']`).forEach(el => {
      if (! (el.classList.contains("logo-light") || el.classList.contains("logo-dark"))) {
        el.setAttribute("class", el.getAttribute("class").replace(RegExp(`${currentTheme}`, 'g'), newTheme))
      }
    })

    for(let el of document.getElementsByClassName(`logo-${currentTheme}`)) {
      el.classList.add("hidden")
    }
    for(let el of document.getElementsByClassName(`logo-${newTheme}`)) {
      el.classList.remove("hidden")
    }
  }
}
