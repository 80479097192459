import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "actions" ]

  connect() {}

  showActions() {
    this.actionsTarget.classList.remove("hidden")
  }

  hideActions() {
    this.actionsTarget.classList.add("hidden")
  }
}
