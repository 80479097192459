import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "icon" ]

  connect() {
    this.setState()
  }

  change() {
    this.setState()
  }

  setState() {
    const theme = document.getElementById("wrapper").getAttribute("data-app-theme")
    const newState = this.newStateCheckbox()

    if (["check_box", "toggle_on"].includes(newState)) {
      this.iconTarget.classList.add(`text-${theme}-primary`)
      this.iconTarget.classList.remove(`text-${theme}-on-surface-60`)
    } else {
      this.iconTarget.classList.add(`text-${theme}-on-surface-60`)
      this.iconTarget.classList.remove(`text-${theme}-primary`)
    }

    this.iconTarget.textContent = newState
  }

  newStateCheckbox() {
    if (this.inputTarget.checked) {
      return "check_box"
    } else if (this.inputTarget.indeterminate) {
      return "indeterminate_check_box"
    } else {
      return "check_box_outline_blank"
    }
  }
}
