import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu" ]

  connect() {}

  toggle(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.menuTarget.classList.toggle("hidden")
  }

  hide(evt) {
    if (this.element.contains(evt.target) === false && !this.menuTarget.classList.contains("hidden")) {
      this.menuTarget.classList.add("hidden")
    }
  }
}
