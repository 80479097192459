import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "sheet", "bg", "content" ]

  connect() {
  }

  toggle() {
    const width = document.documentElement.clientWidth
    if (width >= 1280) {
      this.sheetTarget.classList.toggle("lg:mr-0")
      this.contentTarget.classList.toggle("lg:pr-64")
    } else {
      this.bgTarget.classList.toggle("hidden")
      this.sheetTarget.classList.toggle("-mr-64")
    }
  }
}
