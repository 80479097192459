import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "all", "item" ]

  connect() {
    this.checkAll()
  }

  checkAll() {
    if (this.checkedItemsLen() === 0) {
      this.allTarget.checked = false
    } else if (this.itemsLen() === this.checkedItemsLen()) {
      this.allTarget.checked = true
    } else {
      this.allTarget.checked = false
      this.allTarget.indeterminate = true
    }
    const cb = this.getCheckBox(this.allTarget)
    if (cb) {
      cb.setState()
    }
  }

  itemsLen() {
    return this.itemTargets.length
  }

  checkedItemsLen() {
    return this.itemTargets.filter(el => el.checked).length
  }

  // Events
  toggleAll() {
    const checked = this.itemsLen() !== this.checkedItemsLen()
    let cb

    this.itemTargets.forEach(el => {
      el.checked = checked
      cb = this.getCheckBox(el)
      if (cb) {
        cb.setState()
      }
    })
  }

  change() {
    this.checkAll()
  }

  getCheckBox(el) {
    return this.application.getControllerForElementAndIdentifier(el.parentNode, "checkbox")
  }
}
