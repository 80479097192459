import { Controller } from "stimulus"

const positionAt = (parent, tooltip, posHorizontal, posVertical) => {
  const parentCoords = parent.getBoundingClientRect()
  const dist = 8
  let left
  let top

  switch (posHorizontal) {
    case "left":
      left = parseInt(parentCoords.left) - dist - tooltip.offsetWidth
      if (parseInt(parentCoords.left) - tooltip.offsetWidth < 0) {
        left = dist
      }
      break
    case "right":
      left = parentCoords.right + dist
      if (parseInt(parentCoords.right) + tooltip.offsetWidth > document.documentElement.clientWidth) {
        left = document.documentElement.clientWidth - tooltip.offsetWidth - dist
      }
      break
    default:
      case "center":
        left = parseInt(parentCoords.left) + ((parent.offsetWidth - tooltip.offsetWidth) / 2)
  }

  switch (posVertical) {
    case "center":
      top = (parseInt(parentCoords.top) + parseInt(parentCoords.bottom)) / 2 - tooltip.offsetHeight / 2
      break
    case "bottom":
        top = parseInt(parentCoords.bottom) + dist
        break
    case "top":
    default:
        top = parseInt(parentCoords.top) - tooltip.offsetHeight - dist
  }

  left = (left < 0) ? parseInt(parentCoords.left) : left
  top  = (top < 0) ? parseInt(parentCoords.bottom) + dist : top

  tooltip.style.left = left + "px"
  tooltip.style.top  = top + pageYOffset + "px"
}

export default class extends Controller {
  static targets = []

  initialize() {
    this.over = false
  }

  connect() {
    this.wrapper = document.getElementById("wrapper")
  }

  onMouseOver(evt) {
    if (this.over) return

    const theme = this.wrapper.getAttribute("data-app-theme")
    this.over = true
    const tooltip = document.createElement("div")
    tooltip.className = `tooltip absolute px-2 py-1 text-xs text-${theme}-surface-00 bg-${theme}-on-surface-87 rounded z-24`
    tooltip.innerHTML = this.data.get("text")

    this.wrapper.appendChild(tooltip)

    const pos = this.data.get("position") || "center top"
    const [posHorizontal, posVertical] = pos.split(" ")
    positionAt(evt.currentTarget, tooltip, posHorizontal, posVertical)  
  }

  onMouseOut() {
    this.over = false
    this.wrapper.removeChild(document.querySelector(".tooltip"))
  }
}
