import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "label", "helperText", "errorText" ]

  connect() {
    if (this.inputTarget.getAttribute("autofocus")) {
      this.handleFocus()
    } else {
      this.filledClass()
    }
  }

  focus() {
    this.inputTarget.focus()
  }

  handleFocus() {
    if (this.hasLabelTarget) {
      const theme = document.getElementById("wrapper").getAttribute("data-app-theme")

      this.labelTarget.classList.add("text-xs")
      this.labelTarget.classList.remove("mt-4")
      this.labelTarget.classList.add("mt-2")
      this.labelTarget.classList.remove(`text-${theme}-on-surface-87`)
      this.labelTarget.classList.add(`text-${theme}-primary`)
      this.inputTarget.classList.remove("placeholder-transparent")
      this.inputTarget.classList.add(`placeholder-${theme}-on-surface-38`)
    }
  }

  handleBlur() {
    this.filledClass()
    if (this.hasLabelTarget) {
      const theme = document.getElementById("wrapper").getAttribute("data-app-theme")
      this.inputTarget.classList.remove(`placeholder-${theme}-on-surface-38`)
      this.inputTarget.classList.add("placeholder-transparent")
    }
  }

  filledClass() {
    if (! this.hasLabelTarget) {
      return true
    }

    const inputType = this.data.get("type")
    const valLen = this.inputTarget.value.length
    const theme = document.getElementById("wrapper").getAttribute("data-app-theme")

    this.labelTarget.classList.remove(`text-${theme}-primary`)
    this.labelTarget.classList.add(`text-${theme}-on-surface-87`)

    if (inputType === "date_field" || valLen > 0) {
      this.labelTarget.classList.remove("mt-4")
      this.labelTarget.classList.add("mt-2")
      this.labelTarget.classList.add("text-xs")
    } else {
      this.labelTarget.classList.remove("mt-2")
      this.labelTarget.classList.add("mt-4")
      this.labelTarget.classList.remove("text-xs")
    }
  }

  validateAndShow() {
    this.data.set("showError", "true")
    return this.validate()
  }

  validate() {
    if (this.data.get("showError") === "false") {
      return
    }

    const theme = document.getElementById("wrapper").getAttribute("data-app-theme")
    const error = this.getError()
    this.errorTextTarget.textContent = error
    if (error !== "") {
      if (this.hasHelperTextTarget) {
        this.helperTextTarget.classList.add("hidden")
      }
      this.errorTextTarget.classList.remove("hidden")
      this.labelTarget.classList.add(`text-${theme}-error`)
      this.inputTarget.classList.remove(`focus:border-${theme}-primary`)
      this.inputTarget.classList.add(`border-${theme}-error`)
    } else {
      this.errorTextTarget.classList.add("hidden")
      if (this.hasHelperTextTarget) {
        this.helperTextTarget.classList.remove("hidden")
      }
      this.labelTarget.classList.remove(`text-${theme}-error`)
      this.inputTarget.classList.remove(`border-${theme}-error`)
      this.inputTarget.classList.add(`focus:border-${theme}-primary`)
    }

    return error === ""
  }

  getError() {
    const constraints = JSON.parse(this.data.get("validate"))
    const value = this.inputTarget.value

    for(let el of constraints) {
      switch(el.type) {
        case "required":
          if (value === null || value === "") {
            return el.message
          }
          break
        case "email":
          const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (value !== null && !emailReg.test(String(value).toLowerCase())) {
            return el.message
          }
          break
        case "pattern":
          const patternReg = RegExp(el.pattern)
          if (value !== null && !patternReg.test(value)) {
            return el.message
          }
          break
      }
    }

    return ""
  }
}
