import { Controller } from "stimulus"

const backgrounds = ['#bf4675', '#5697c3', '#820394', '#56952c', '#e79825', '#be3b1b', '#4765c8']

export default class extends Controller {
  static targets = [ "canvas" ]

  connect() {
    // this.canvasTarget.textContent = 
    const ctx = this.canvasTarget.getContext("2d")
    new Chart(ctx, {
      type: 'pie',
      data: this.chartData(),
      options: this.options()
    })
  }

  chartData() {
    const rs = JSON.parse(`${this.data.get("rs")}`)
    const label = this.data.get("label")
    const display = this.data.get("display")
    const displayLabel = `${display}Label`

    if (!rs) {
      return null
    }

    const { details } = rs

    const labels = []
    const data = []
    const backgroundColor = []

    for (let i = 0; i < details.length; i += 1) {
      labels.push(`${details[i][label]}: ${details[i][displayLabel]}`)
      data.push(details[i][display])
      backgroundColor.push(backgrounds[i])
    }

    return {
      labels,
      datasets: [{
        data,
        backgroundColor,
      }],
    }
  }

  // currencyOptions = (display) => {
  //   if (display === 'total') {
  //     return {
  //       formatWithSymbol: true,
  //     };
  //   }
  //   return {
  //     formatWithSymbol: false,
  //     precision: 0,
  //   };
  // }

  // numberToCurrency = (val, display) => currency(val, this.currencyOptions(display)).format();

  options() {
    return {
      legend: {
        position: 'left',
      },
      tooltips: {
        callbacks: {
          label: (item, data) => data.labels[item.index],
        },
      },
    }
  }
}
